.home__hero-section {
    color: #99956f;
    padding: 50px 0;
    max-width: 2000px;
    margin: auto;
}

.home__hero-row {
    align-items: center;
}

.row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
}

.col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
}

.colcarousel {
    align-self: start;
}

.home__hero-text-wrapper {
    max-width: 768px;
    padding-top: 0;
    padding-bottom: 60px;
}

.top-line {
    color: #01a2cf;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.heading {
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 1.1;
    letter-spacing: 1.2px;
    font-weight: 600;
    color: #99956f;
}

.complexDescription {
    line-height: 135%;
    margin-bottom: -10px;
    margin-top: 10px;
}

.simpleDescription {
    line-height: 135%;
    margin-top: 24px;
    /*margin-bottom: -10px;*/
}

.newline {
    margin-top: 10px;
}

.newlineprices {
    margin-top: 2px;
}

.pointsTitle {
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 6px
}

.dark {
    color: #aba371;
}

.darkBg {
    background-color: #f1eee4;
}

.lightBg {
    background-color: #ffffff;
    /*border-right: 1px solid #f1eee4;*/
    /*border-left: 1px solid #f1eee4;*/
}

.home__hero-subtitle {
    max-width: 1200px;
    margin-bottom: 35px;
    font-size: 16px;
    font-family: Segoe;
    text-align: justify;
    line-height: 24px;
}

.home__hero-img-wrapper {
    max-width: 1200px;
}

.home__hero-img {
    /*max-width: 95%;*/
    width: 95%;
    margin-top: 0;
    margin-right: 0;
    margin-left: 10px;
    padding-right: 0;
}

img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

.read-more-link{
    /*color: blueviolet;*/
    text-decoration: underline;
    cursor: pointer;
}

.flex-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 10px
}

@media (max-width: 800px) {
    .flex-container {
        flex-direction: column;
    }
}

.features {
    margin-right: 10px;
    line-height: 24px;
}

@media screen and (max-width: 991px) {
    .container {
        padding-right: 30px;
        padding-left: 30px;
    }
}

@media screen and (max-width: 900px) {
    .home__hero-text-wrapper {
        padding-bottom: 10px;
    }

    .col {
        max-width: 100%;
        flex-basis: 100%;
    }
}
