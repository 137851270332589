.carousel .slide img {
    max-height: 1000px;  /* change this to whatever you want */
    max-width: 2000px;
    background-color: #f1eee4;
}

.carousel-wrapper {
    /*max-height: 1000px;  !* change this to whatever you want *!*/
    max-width: 2000px;
    background-color: #f1eee4;
    margin: auto;
}

.carousel-viewport {
    max-height: 1000px; /* change this to whatever you want */
}

.whiteoverride {
    background-color: white !important;
}

/*.carousel {*/
/*    background-color: #f1eee4;*/
/*}*/



