* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

@font-face {
  font-family: 'Patrick';
  src: local('Patrick'), url(./fonts/patrick-hand.regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Segoe';
  src: local('Segoe'), url(./fonts/seguisym.ttf) format('truetype');
}

/*remove the blue highlight on buttons on mobile*/
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

@media screen and (max-width: 900px) {
  .carousel .carousel-arrow-default {
    opacity: 0.5;
    bottom: 13px !important;
  }
  .carousel .carousel-arrow {
    opacity: 0.5;
    bottom: 13px !important;
  }
}
