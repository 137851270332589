.footer-container {
    background-color: #01a2cf;
    padding: 1rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 2000px;
    margin: auto;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 12px;
    padding: 12px;
    color: #fff;
}

.footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input {
    padding: 8px 24px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
}

.footer-icon {
    margin-right: 0.5rem;
}

.footer-name {
    font-family: Patrick;
    padding-top: 10px;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    /*align-items: flex-start;*/
    align-items: center;
    margin: 8px;
    text-align: left;
    /*width: 160px;*/
    box-sizing: border-box;
    padding: 4px;
}

.footer-link-items h2 {
    margin-bottom: 16px;
}

.footer-link-items > h2 {
    color: #fff;
}

.footer-link-items p {
    color: #fff;
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
    color: #fff;
    font-size: 34px;
}

.social-media {
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap {
    display: flex;
    /*justify-content: space-between;*/
    justify-content: space-evenly;
    align-items: center;
    width: 95%;
    max-width: 1000px;
    margin: 5px auto 0 auto;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
}

.social-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    /*margin-bottom: 10px;*/
}

.copyright {
    margin-left: 10px;
    font-size: 1rem;
}

.website-rights {
    color: #fff;
    margin-bottom: 16px;
    padding-top: 10px;
}

@media screen and (max-width: 820px) {
    /*.footer-links {*/
    /*    padding-top: 2rem;*/
    /*}*/

    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper {
        flex-direction: column;
    }

    .social-media-wrap {
        flex-direction: column;
    }
}
