.navbar {
    background: #01a2cf;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    max-width: 2000px;
    margin: auto;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    position: relative;
}

.container {
    z-index: 1;
    width: 100%;
    max-width: 2100px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2.5rem;
    letter-spacing: 1.2px;

    display: flex;
    align-items: center;
}

.navbar-name {
    font-family: Patrick;
    display: inline-block;
    padding-top: 10px;
}

.navbar-icon {
    margin-right: 0.5rem;
}

.nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    /*justify-content: end;*/
}

.nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.nav-item:hover {
    border-bottom: 2px solid #ffffff;
}

.nav-links {
    color: #ffffff;;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: Segoe;
}

.fa-bars {
    color: #fff;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #f1eee4;
        left: 0;
        opacity: 1;
        transition: all 0.6s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        cursor: pointer;
        color: #aba371;
    }

    .nav-links:hover {
        color: #f00946;
        transform: scale(1.2);
        transition: all 0.3s ease;
    }

    .nav-item:hover {
        border: none;
    }

    .nav-item {
        width: 100%;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(5%, 10%);
        font-size: 2.05rem;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 2rem;
        letter-spacing: 1.2px;
        cursor: pointer;
        z-index: 1;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 220px;
    }

    .btn-link {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        padding: 8px 8px;
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .navbar-logo {
        font-size: 1.8rem;
        transform: translate(3%,20%);
    }
}

@media screen and (max-width: 350px) {
    .navbar-logo {
        transform: translate(3%,30%);
        font-size: 1.4rem;
    }
}
