.intro-container {
    color: #aba371;
    padding: 5px 50px;
    max-width: 2000px;
    margin: auto;
    font-size: 1.3rem;
    margin-top: 22px;
    text-align: justify;
}

.borderbottom {
    border-bottom: 2px solid #01a2cf;
    padding: 7px 50px;
}

@media screen and (max-width: 991px) {
    .intro-container {
        padding-right: 30px;
        padding-left: 30px;
        font-size: 1rem;
    }
}