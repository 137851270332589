.carousel .slide img {
    max-height: 900px;  /* change this to whatever you want */
    max-width: 1000px;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: .8;
}

.whiteoverride {
    background-color: white !important;
}

/*.carousel .control-prev.control-arrow:before {*/
/*    border-right: 16px solid #fff;*/
/*}*/

/*.carousel .control-next.control-arrow:before {*/
/*    border-left: 16px solid #fff;*/
/*}*/

/*.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {*/
/*    border-top: 16px solid transparent;*/
/*    border-bottom: 16px solid transparent;*/
/*}*/
